<template>
  <div class="wardan">
    <h1>Wardan Is Here</h1>
    <h4>Select One of my friends:</h4>
    <div class="friend" v-for="friend in friends" :key="friend.age">
      <router-link
        :to="{
          name: 'WardanTell',
          params: { name: friend.name, age: friend.age, status: friend.status },
        }"
      >
        <h2>Name: {{ friend.name }}</h2>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      friends: [
        { name: "Emad Alhadire", age: 24, status: "Single" },
        { name: "Monther Al-Majrashi", age: 25, status: "Complicated" },
        { name: "Redhwan Al-Tall", age: 26, status: "In so much love " },
      ],
    };
  },
})
</script>

<style>
.wardan {
  margin-left: auto;
  margin-right: auto;
  display: table;
}
.friend a {
  text-decoration: none;
}
.wardan h2 {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  margin: 10px auto;
  max-width: 600px;
  cursor: pointer;
  color: #444;
  text-decoration: none;
}
.wardan h2:hover {
  background-color: #ddd;
}
</style>
