import {ref} from 'vue';
import { useRouter } from "vue-router";

export default function protofolioHomePage(){
    const router = useRouter();
  let Wardan = ref("Wardan");
  let WardanHere = ref(true);
  let WardanShow = ref(false);
  let WardanImage = ref("/www.png");
  let Job = ref("Web Developer");
  let Info = ref(
    "Multidisciplinary web developer who hacks at, <br> makes and occasionally breaks things. <br>"
  );
  let Specialty = ref(
    "Front End Developer / Back End Developer / Full Stack Developer"
  );
  let details = ref(["Brown", "Tall", "25", "Single"]);

  function toAbout() {
    router.push({
      name: "About",
    });
  }

  return {
    Wardan,
    WardanHere,
    WardanShow,
    WardanImage,
    Job,
    Info,
    Specialty,
    details,
    toAbout,
  };
}