<template>
  <div class="contact">
    <ContactForm />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
 import ContactForm from "../components/ContactForm.vue";

export default defineComponent({
  components: {
     ContactForm,
  },
})
</script>

<style>
.contact {
  margin: 0;
  position: relative;
  overflow: hidden;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, 10%);   width: 500px;
padding:1rem;
}
</style>
