<template>
  <header class="main-header">
    <nav class="main-nav">
      <div @click="navBar" class="toggle-button a">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>

      <div class="nav-links">
        <ul>
          <li @click="closeNav">
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>

          <li @click="closeNav">
            <router-link :to="{ name: 'About' }">About</router-link>
          </li>
          <li @click="closeNav">
            <router-link :to="{ name: 'Work' }">Work</router-link>
          </li>

          <li @click="closeNav">
            <Toggle :mode="mode" @toggle="$emit('toggle')" />
          </li>
          <li class="tog">
            <div class="mode modd">{{ mode }} mode</div>
          </li>
          <li @click="closeNav">
            <router-link class="Contact" :to="{ name: 'Contact' }"
              >Contact</router-link
            >
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Toggle from "../components/Toggle.vue";

export default defineComponent({
  props: ["mode"],
  name: "NavPage",

  components: {
    Toggle,
  },

  setup() {
    const wardan: string = "wardan";
    const navBar = () => {
      let navLinks = document.getElementsByClassName("nav-links")[0];
      console.log("Loveeeee wardan");
      navLinks.classList.toggle("active");
    };
    const closeNav = () => {
      let navLinks = document.getElementsByClassName("nav-links")[0];
      navLinks.classList.toggle("active");
    };

    console.log("ilove u:", wardan);

    return { navBar, closeNav, wardan };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.mode {
  position: relative;
  top: 15.5px;
  right: -350.5px;
  color: white;
}
.main-header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  z-index: 500;
  background-color: rgb(2, 2, 14);
  overflow: hidden;
}

.main-nav {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
}

.Contact {
  position: fixed;
  top: 0;
  right: 5rem;
}

.main-nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  margin-left: 2em;
}

.main-nav li {
  list-style: none;
}

.main-nav a {
  text-decoration: none;
  padding: 1.2rem;
  display: block;
  color: white;
}

.main-nav a:hover {
  color: #adadad;
}

#Home {
  color: #f0860c;
}

.toggle-button {
  display: none;
  position: absolute;
  top: 0;
  left: 1rem;
  flex-direction: column;
  justify-content: space-between;
}

.bar {
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background-color: white;
}

@media (max-width: 800px) {
  .toggle-button {
    display: flex !important;
    background-color: rgb(20, 19, 19);
    border-radius: 25%;
    position: fixed;
    top: 0.5rem;
    left: 0.2rem;
    width: 65px;
    height: 60px;
    cursor: pointer;
  }

  .main-nav {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .main-nav ul {
    width: 100%;
    flex-direction: column;
  }
  .main-nav li {
    text-align: center;
    padding: 25px;
  }
  .main-nav li a {
    padding: 0.5rem 0.5rem;
  }

  .Contact {
    position: relative;
    right: 0;
  }
  .nav-links {
    display: none;
    width: 100%;
    
  }

  .nav-links ul {
    width: 100%;
    height: 100vh;
    flex-direction: column;
  }
  .nav-links ul li {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin: 1rem;
  }
  .nav-links ul li:hover {
    background-color: black;
  }

  .a {
    padding: 1rem 1rem;
  }

  .nav-links.active {
    display: flex;

  }

  .tog {
    display: none;
  }
  .modd {
    position: relative;
    right: 0;
    top: 0;
  }
}
</style>
