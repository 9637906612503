<template>
  <div class="home-page">
    <section class="home-main-section">
      <div class="img-rapper">
        <img v-bind:src="WardanImage" class="wardan-img" />

        <div class="ttext" id="ttext">
          <h3 v-if="WardanHere" class="me">Hi, I'm {{ Wardan }}</h3>
          <!-- v-show if false  will display none -->
          <h3 v-show="WardanShow" class="me">Hi, I'm {{ Wardan }}</h3>
          <div v-show="WardanShow"></div>
          <div class="title">{{ Job }}. Product Person.</div>
          <div class="pation">
            <div v-html="Info"></div>
            <span id="job">{{ Specialty }} </span> <br />
            <br />
            <span>
              <button class="learnMore" @click="toAbout">Learn More ></button>
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import protofolioHomePage from "@/use/protofolioHomePage.js";
export default defineComponent({
  name: "HomePage",
  inject: ["GStore"],

  setup() {
    const {
      Wardan,
      WardanHere,
      WardanShow,
      WardanImage,
      Job,
      Info,
      Specialty,
      toAbout,
    } = protofolioHomePage();
    return {
      Wardan,
      WardanHere,
      WardanShow,
      WardanImage,
      Job,
      Info,
      Specialty,
      toAbout,
    };
  },

  // methods: {
  //   toAbout() {
  //     this.GStore.flashMessage = "You are now in About Page";
  //     console.log(this.GStore.flashMessage);
  //     setTimeout(() => {
  //       this.GStore.flashMessage = "";
  //     }, 5000);
  //     this.$router.push({
  //       name: "About",
  //     });
  //   },
  // },
});
//The function can be used here or in external file.
// function protofolioHomePage() {

// }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-page {
  position: absolute;
  top: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.home-main-section {
  display: flex;
  height: 100vh;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
}

.home-main-section .img-rapper {
  display: flex;
  flex-direction: row-reverse;
}

.home-main-section .wardan-img {
  position: fixed;

  top: 60px;
  right: 14px;
  border-radius: 5%;
  max-height: 90vh;
  min-width: 45vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  transform: scaleX(-1);
}
.home-main-section .wardan-img:hover {
  /* display:none; */
}

.home-main-section .ttext {
  width: 60vw;
  margin-top: 6em;
  padding-left: 6em;
  text-align: left;
}

.me {
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 2.7em;
}

.title {
  margin-bottom: 2.5rem;
  font-size: 64px;
  font-weight: 600;
  text-transform: none;
  position: relative;
  top: -30px;
}
.pation {
  opacity: 1;
  font-weight: 300;
  font-size: 31px;
  margin-top: 4rem;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition: opacity 1000ms cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    transform 1000ms ease 0s;
  position: relative;
  top: -60px;
}

#job {
  margin-top: 8px;
  font-size: 15px;
}

.learnMore {
  font-weight: 300;
  font-size: 18px;
  color: #f0860c;
  text-decoration: none;
  cursor: pointer;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
}
@media (max-width: 900px) {
  .home-main-section {
    overflow: scroll;
    position: relative;
  }

  .home-main-section .ttext {
    width: 70vw;
    margin-top: 6em;
    padding-left: 1em;
    text-align: left;
    overflow-y: scroll;
    box-sizing: content-box;
  }
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .home-main-section .wardan-img {
    position: relative;
    top: 70px;
    right: 150px;
    border-radius: 5%;
    max-height: 140vh;
    min-width: 45vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}
</style>
