<template>
  <div class="about">
    <section class="home-main-section">
      <div class="img-rapper">
        <img v-bind:src="WardanImage" class="wardan-img" />

        <div class="ttext" id="ttext">
          <h1 class="title">Quality is what i look for</h1>
          <div class="pation">
            {{ me }}
            <br />
            <div class="working">
              Working from Johor next to Singapore currently. I have completed
              my degree in University Technology Malaysia in Computer Science
              especially network and security. I have found a great passion for
              web development front-end and backend last year when I was working
              on my final year project.
              <br />
            </div>
            <div class="working2">
              I strive to create elegant solutions that surprise and delight
              users, while keeping complex technical dependencies in mind for
              implementation, scalability and developer sanity <br />
            </div>
            <div class="working3">
              Have an interesting project?
              <span>
                <router-link class="learnMore" :to="{ name: 'Contact' }"
                  >Learn More ></router-link
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      me: "I am Mohammed Wardan, but you can just call me Wardan",
      WardanImage: "/www.png",
      Job: "Web Developer",
      Info: " Multidisciplinary web developer who hacks at, <br> makes and occasionally breaks things. <br>",
      Specialty:
        "Front End Developer / Back End Developer / Full Stack Developer",
      details: ["Brown", "Tall", "25", "Single"],
    };
  },
});
</script>

<style scoped>
.learnMore {
  font-weight: 300;
  color: #f0860c;
  text-decoration: none;
}
.about {
  position: absolute;
  top: 0;
}
.working {
  font-weight: 400;
  line-height: 34px;
  font-size: 18px;
}
.working2 {
  margin-top: 2rem;
  font-weight: 400;
  line-height: 34px;
  font-size: 18px;
}
.working3 {
  margin-top: 2rem;
  font-weight: 400;
  line-height: 34px;
  font-size: 18px;
}

.home-main-section {
  display: flex;
  height: 100vh;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
  overflow: scroll;
  overflow-x: hidden;
}

.home-main-section .img-rapper {
  display: flex;
  flex-direction: row-reverse;
}

.home-main-section .wardan-img {
  position: relative;
  top: 60px;
  right: 100px;
  border-radius: 5%;
  max-height: 90vh;
  min-width: 45vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  transform: scaleX(-1);
}
.home-main-section .wardan-img:hover {
  /* display:none; */
}

.home-main-section .ttext {
  width: 60vw;
  margin-top: 6em;
  padding-left: 6em;
  text-align: left;
}

.me {
  font-weight: 600;
  font-size: 28px;
}

.title {
  font-size: 64px;
  font-weight: 600;
  text-transform: none;
  position: relative;
  top: -30px;
}
.pation {
  opacity: 1;
  font-weight: 300;
  font-size: 21px;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  transition: opacity 1000ms cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    transform 1000ms ease 0s;
  position: relative;
  top: -60px;
}

#job {
  margin-top: 1em;
  font-size: 11px;
}

.learnMore {
  font-weight: 300;
  color: #f0860c;
  text-decoration: none;
}
@media (max-width: 500px) {
  .home-main-section {
    overflow: scroll;
    position: relative;
  }

  .home-main-section .ttext {
    width: 70vw;
    margin-top: 6em;
    padding-left: 1em;
    text-align: left;
    overflow-y: scroll;
    box-sizing: content-box;
  }
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .home-main-section .wardan-img {
    position: relative;
    top: 70px;
    right: 150px;
    border-radius: 5%;
    max-height: 140vh;
    min-width: 45vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}
</style>
