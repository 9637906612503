
import { defineComponent} from "vue";
export default defineComponent({ 
  props: ["name", "age", "status"],
  data() {
    //  return{
    //    id: this.$route.params.id
    //  }
  },
})

