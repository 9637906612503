import { ref } from 'vue';
import emailjs from "emailjs-com";

export default function sendForm() {

    var fromName = ref('');
    var toName = ref('');
    var message = ref('');

    const sendEmail = (e) => {

        e.preventDefault();

            emailjs.sendForm('service_r8vhs9r', 'template_xdkn24f', e.target, 'user_RWZHJPdTDXMKXFyq7mMi9').then(response => {
                console.log(response);
            })


    }




    return { fromName, toName, message, sendEmail }

}