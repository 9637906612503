
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      me: "I am Mohammed Wardan, but you can just call me Wardan",
      WardanImage: "/www.png",
      Job: "Web Developer",
      Info: " Multidisciplinary web developer who hacks at, <br> makes and occasionally breaks things. <br>",
      Specialty:
        "Front End Developer / Back End Developer / Full Stack Developer",
      details: ["Brown", "Tall", "25", "Single"],
    };
  },
});
