<template>
  <div class="app" :class="mode">
    <!-- <div id="flashMessage" v-if="GStore.flashMessage">
      {{ GStore.flashMessage }}
    </div> -->
      <NavPage :bfc="bfc" :mode="mode"  @toggle="toggle" />
  <router-view />
  </div>
</template>

<script lang="ts">
import NavPage from "../src/components/NavPage.vue";
import { ref } from "vue";
export default {
  inject: ["GStore"],
  name: "App",
  components: {
    NavPage,
  },
  setup() {
    const capacity = ref(5);
    let mode = ref("Dark");
    let bfc = ref("black");

    const toggle = () => {
      mode.value === "Dark" ? (mode.value = "Light") : (mode.value = "Dark");

      mode.value === "Dark"
        ? (bfc.value = "white")
        : (bfc.value = "black");
     console.log("the background color: " + bfc.value);
    };
     
     
    return { capacity, mode, bfc, toggle };
  },
};
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
   overflow-x: hidden
}

.app {
  width: 100vw;
  min-height: 110vh;
  background: #f3f3f3;
  transition: background 0.8s ease-in-out;
}
.Dark{
  background: #001;
  color: white;

}
.Light{
  background: #F3F3F3;
  color: #001;
  }
@keyframes yellowfade {
  from {
    background: yellow;
  }
  to {
    background: transparent;
  }
}

#flashMessage {
  position: absolute;
  top: 100px;
  right: 450px;
  animation-name: yellowfade;
  animation-duration: 3s;
}
</style>
