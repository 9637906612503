<template>
  <div class="ops">
    <h1>Oops!</h1>
    <h3>The page you're looking for is not here.</h3>
    <router-link :to="{ name: 'Home' }"> Back to the home page </router-link>
  </div>
</template>

<style>
.ops {
  position: absolute;
  top: 100px;
  left: 500px;
}
</style>
