<template>
  <div class="work">
    <div id="wrapper">
      <div id="content">
        <ul id="movieposters">
          <li>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/8/81/UTM-LOGO.png"
              alt="Iron Man 2"
              width="200px"
              height="300px"
            />
            <div class="movieinfo">
              <h3>ODMS</h3>
              <p>
                Web Application (Final Year Project) for the faculty
                of computing at UTM JB where students, supervisors, and examiners
                can manage the viva examination processesusing the proposed
                online system.
              </p>
              <a href="https://github.com/mrwardan/FYP-2" title="Iron Man 2"
                >More info</a
              >
            </div>
          </li>
          <li>
            <img
              src="https://play-lh.googleusercontent.com/U6vBUAtKTTdPImtpg7f8fpyWtD5ju19LrMU9jjzWO0yJaMJHHdJfUVgg1s0xuLUgTRPh=s180-rw"
              alt="The Last Airbender"
              width="200px"
              height="300px"
            />
            <div class="movieinfo">
              <h3>iGuide</h3>
              <p>
                During my 5th semester at UTM, I had the subject Application
                Development where I have worked on developing a mobile application
                that provides a disruptive solution for the problem of new
                university students being unable to get their administrative
                matters done due to unfamiliarity of the new environment.
               
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.iguide.prod&hl=en"
                title="Iron Man 2"
                >More info</a
              >
            </div>
          </li>
          <li>
            <img
              src="https://seeklogo.com/images/E/e_visa-logo-F05319F3FF-seeklogo.com.png"
              alt="Tron Legacy"
              width="200px"
              height="300px"
            />
            <div class="movieinfo">
              <h3>UTM E-Visa</h3>
              <p>
                During my 4th semester in UTM I had the subject Web
                Programming where I got to work on this project. The project was
                to develop a fully functional web application to manage UTM
                international students visa renewal process, which was being
                done manually. 
              </p>
              <a
                href="https://github.com/mrwardan/UTM-e-visa"
                title="Tron Legacy"
                >More info</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.work {
  position: absolute;
  top: 2%;
  left: 30%;
}

/* BASIC RESET */
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
body,
html,
p,
blockquote,
fieldset,
input {
  margin: 0;
  padding: 0;
}

/* HTML ELEMENTS */
body {
  background-color: #deddcd;
  font: 14px/21px Arial, Helvetica, sans-serif;
}

/* COMMON CLASSES */
.break {
  clear: both;
}

/* WRAPPER */
#wrapper {
  width: 960px;
  margin: 40px auto;
}

/* CONTENT */
#content {
}
#content .info {
  padding: 10px;
}

/* MOVIE POSTERS */
#movieposters {
  list-style: none;
  margin: 100px 0;
  height: 550px;
}
#movieposters li {
  display: inline;
  float: left;
  -webkit-perspective: 600;
  -webkit-transform-style: preserve-3d;
  -webkit-transition-property: perspective;
  -webkit-transition-duration: 1.5s;
}
#movieposters li:hover {
  -webkit-perspective: 5000;
}

#movieposters li img {
  border: 10px solid #fcfafa;
  -webkit-transform: rotateY(30deg);
  -moz-box-shadow: 0 3px 10px #888;
  -webkit-box-shadow: 0 3px 10px #888;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 0.5s;
}
#movieposters li:hover img {
  -webkit-transform: rotateY(0deg);
}

.movieinfo {
  color: black;
  border: 5px solid #fcfafa;
  padding: 20px;
  width: 200px;
  height: 350px;
  font-size: 14px;
  background-color: #deddcd;
  margin: -195px 100px 100px 55px;
  position: absolute;
  -moz-box-shadow: 0 20px 40px #888;
  -webkit-box-shadow: 0 20px 40px #888;
  -webkit-transform: translateZ(30px) rotateY(30deg);
  -webkit-transition-property: transform, box-shadow, margin;
  -webkit-transition-duration: 0.5s;
}

#movieposters li:hover .movieinfo {
  -webkit-transform: rotateY(0deg);
  -webkit-box-shadow: 0 5px 10px #888;
  margin: -175px 0 0 5px;
}

.movieinfo h3 {
  color: #7a3f3a;
  font-variant: small-caps;
  font-family: Georgia, serif, Times;
  text-align: center;
  padding-bottom: 15px;
}
.movieinfo p {
  padding-bottom: 15px;
}
.movieinfo a {
  background-color: #7a3f3a;
  padding: 5px 10px;
  color: #eee;
  text-decoration: none;
  display: block;
  width: 80px;
  text-align: center;
  margin: 0 auto;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.movieinfo a:hover,
.movieinfo a:focus {
  background-color: #6a191f;
  color: #fff;
}
</style>
