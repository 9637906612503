
import { defineComponent } from "vue";
import Toggle from "../components/Toggle.vue";

export default defineComponent({
  props: ["mode"],
  name: "NavPage",

  components: {
    Toggle,
  },

  setup() {
    const wardan: string = "wardan";
    const navBar = () => {
      let navLinks = document.getElementsByClassName("nav-links")[0];
      console.log("Loveeeee wardan");
      navLinks.classList.toggle("active");
    };
    const closeNav = () => {
      let navLinks = document.getElementsByClassName("nav-links")[0];
      navLinks.classList.toggle("active");
    };

    console.log("ilove u:", wardan);

    return { navBar, closeNav, wardan };
  },
});
