
import NavPage from "../src/components/NavPage.vue";
import { ref } from "vue";
export default {
  inject: ["GStore"],
  name: "App",
  components: {
    NavPage,
  },
  setup() {
    const capacity = ref(5);
    let mode = ref("Dark");
    let bfc = ref("black");

    const toggle = () => {
      mode.value === "Dark" ? (mode.value = "Light") : (mode.value = "Dark");

      mode.value === "Dark"
        ? (bfc.value = "white")
        : (bfc.value = "black");
     console.log("the background color: " + bfc.value);
    };
     
     
    return { capacity, mode, bfc, toggle };
  },
};
