<template>
  <div class="wardan">
    <h1>^_^</h1>
    <h1>
      <div class="friend">
        <h2>Name: {{ name }}</h2>
        <h2>Age: {{ age }}</h2>
        <h2>Status: {{ status }}</h2>
      </div>
    </h1>
  </div>
</template>

<script lang="ts">
import { defineComponent} from "vue";
export default defineComponent({ 
  props: ["name", "age", "status"],
  data() {
    //  return{
    //    id: this.$route.params.id
    //  }
  },
})

</script>

<style>
.wardan {
  position: absolute;
  top: 100px;
  left: 500px;
}
</style>
