
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      friends: [
        { name: "Emad Alhadire", age: 24, status: "Single" },
        { name: "Monther Al-Majrashi", age: 25, status: "Complicated" },
        { name: "Redhwan Al-Tall", age: 26, status: "In so much love " },
      ],
    };
  },
})
