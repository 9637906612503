
import { defineComponent } from "vue";
import protofolioHomePage from "@/use/protofolioHomePage.js";
export default defineComponent({
  name: "HomePage",
  inject: ["GStore"],

  setup() {
    const {
      Wardan,
      WardanHere,
      WardanShow,
      WardanImage,
      Job,
      Info,
      Specialty,
      toAbout,
    } = protofolioHomePage();
    return {
      Wardan,
      WardanHere,
      WardanShow,
      WardanImage,
      Job,
      Info,
      Specialty,
      toAbout,
    };
  },

  // methods: {
  //   toAbout() {
  //     this.GStore.flashMessage = "You are now in About Page";
  //     console.log(this.GStore.flashMessage);
  //     setTimeout(() => {
  //       this.GStore.flashMessage = "";
  //     }, 5000);
  //     this.$router.push({
  //       name: "About",
  //     });
  //   },
  // },
});
//The function can be used here or in external file.
// function protofolioHomePage() {

// }
